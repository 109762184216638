<template>
    <footer
        ref="scrollBox"
        class="relative pt-[15.5rem]"
        :class="{ 'bg-black text-white': theme === 'dark', 'bg-gray-200': theme === 'light' }"
    >
        <div class="z-3 relative container grid grid-cols-12 lg:grid-rows-[1fr_auto] lg:gap-x-7 mb-6 sm:mb-12 lg:mb-20">
            <div
                class="order-2 lg:order-1 lg:row-span-2 col-span-12 lg:col-span-3 flex flex-col justify-end mt-5 lg:mt-0 lg:pt-32"
            >
                <div class="link-block pb-4 mb-4 lg:pb-8 lg:mb-8">
                    <LigetLogo v-if="false" class="w-[15rem] mb-12 hidden lg:block" :white="theme === 'dark'" />
                    <div class="primary-text"><T t="footer.company_name" /></div>
                    <div class="secondary-text"><T t="footer.address" /></div>
                    <a href="mailto:office@ligetbudapest.hu" class="secondary-text">office@ligetbudapest.hu</a>
                    <a v-if="false" href="tel:+3613743130" class="secondary-text">+36 1/374 3130</a>
                </div>
                <div class="link-block pb-4 mb-4 lg:pb-8 lg:mb-8">
                    <FooterMenuLink
                        :item="{
                            url: validatedCustomerSupportUrl,
                            title: $t('footer.customer_support_link_label'),
                            new_window: true,
                        }"
                        class="primary-text"
                    />

                    <a href="mailto:ugyfelszolgalat@ligetbudapest.hu" class="secondary-text">
                        ugyfelszolgalat@ligetbudapest.hu
                    </a>
                    <a href="tel:+3613743131" class="secondary-text">+36 1/374 3131</a>
                </div>
                <div class="link-block lg:border-none pb-4 mb-4 lg:pb-0 lg:mb-0">
                    <div v-if="menuZones.FooterLeftColumn?.menuItems.length" class="flex flex-col items-start">
                        <FooterMenuLink
                            v-for="item in menuZones.FooterLeftColumn.menuItems"
                            :key="`footer-left-${item.id}`"
                            :item="item"
                            class="primary-text"
                        />
                    </div>
                    <button v-if="false" class="primary-text hover:underline">
                        <T t="footer.cookie_settings_button_label" />
                    </button>
                </div>
            </div>
            <div
                class="order-3 lg:order-2 lg:row-span-2 col-span-12 lg:col-span-3 lg:col-start-5 flex flex-col justify-end lg:pt-32"
            >
                <div
                    v-if="menuZones.FooterCenterColumnTop?.menuItems.length"
                    class="link-block pb-4 mb-4 lg:pb-8 lg:mb-8"
                >
                    <FooterMenuLink
                        v-for="item in menuZones.FooterCenterColumnTop.menuItems"
                        :key="`footer-left-${item.id}`"
                        :item="item"
                        class="primary-text"
                    />
                </div>
                <div
                    v-if="menuZones.FooterCenterColumnBottom?.menuItems.length"
                    class="link-block lg:border-none pb-4 mb-4 lg:pb-0 lg:mb-0"
                >
                    <FooterMenuLink
                        v-for="item in menuZones.FooterCenterColumnBottom.menuItems"
                        :key="`footer-left-${item.id}`"
                        :item="item"
                        class="primary-text"
                    />
                </div>
            </div>
            <div
                class="order-1 lg:order-3 lg:row-span-1 col-span-12 lg:col-span-4 lg:col-start-9 flex flex-col-reverse lg:flex-col justify-end"
                :class="{ 'lg:pt-64': $i18n.locale !== 'hu' }"
            >
                <div>
                    <div class="font-Poppins text-lg font-semibold">
                        <T t="footer.socials_title" />
                    </div>
                    <div class="flex items-center mt-7">
                        <SocialIcon
                            type="instagram"
                            url="https://www.instagram.com/liget_budapest/"
                            class="mr-4"
                            :white="theme === 'dark'"
                        />
                        <SocialIcon
                            type="facebook"
                            url="https://www.facebook.com/miligetunk/"
                            class="mx-4"
                            :white="theme === 'dark'"
                        />
                        <SocialIcon
                            type="youtube"
                            url="https://www.youtube.com/channel/UCQnLn4FHzg7nj5LJ6w5voKA"
                            class="mx-4"
                            :white="theme === 'dark'"
                        />
                        <SocialIcon
                            type="linkedin"
                            url="https://www.linkedin.com/company/75430226/"
                            class="ml-4"
                            :white="theme === 'dark'"
                        />
                    </div>
                </div>
                <div
                    v-if="$i18n.locale === 'hu'"
                    class="bg-white text-black rounded-3xl px-3 py-4 sm:p-6 mb-8 lg:mb-0 mt-6 lg:mt-12"
                >
                    <div class="flex items-center justify-center sm:justify-start mb-3">
                        <object
                            class="w-5 mr-2"
                            type="image/svg+xml"
                            aria-label="newsletter subscription illustration"
                            style="filter: unset"
                            :data="require('~/assets/images/icons/subscribe.svg')"
                        ></object>
                        <div class="font-Poppins font-semibold text-xl uppercase">
                            <T t="footer.newsletter_title" />
                        </div>
                    </div>
                    <p class="sm:font-bold text-center sm:text-left mb-5">
                        <T t="footer.newsletter_description" />
                    </p>
                    <div class="text-center">
                        <Button :to="localePath('newsletter')" :label="$t('footer.newsletter_button_label')" />
                    </div>
                </div>
            </div>
            <div class="order-4 lg:order-4 lg:row-span-1 col-span-12 lg:col-span-4 lg:col-start-9">
                <div v-if="false" class="lg:mt-8">
                    <T t="footer.for_event_organizers_text" />
                </div>

                <div v-if="menuZones.FooterRightColumn?.menuItems.length" class="link-block border-none lg:mt-4">
                    <FooterMenuLink
                        v-for="item in menuZones.FooterRightColumn.menuItems"
                        :key="`footer-left-${item.id}`"
                        :item="item"
                        class="primary-text"
                    />
                </div>
            </div>
        </div>

        {{/* YELLOW BAR AT THE BOTTOM */}}
        <div class="relative bg-yellow-400 text-black z-3">
            <div class="container py-2 sm:py-3">
                <span class="uppercase text-sm relative copyright-text">
                    &copy; liget budapest
                    <span v-if="false" class="ml-4 vw-ref lowercase"> {{ $store.state.windowWidth }}px </span>
                </span>
            </div>
        </div>

        {{/* BACKGROUND LAYERS */}}
        <div class="z-2 absolute top-0 left-0 w-full h-full overflow-hidden">
            <div v-if="isVisitorInformationPage" class="z-3 absolute w-full h-full">
                {{/* People */}}

                <div class="relative container h-full">
                    {{/* Girl sitting with ice cream */}}
                    <div
                        class="bg-illustration top-[7.5rem] xs:top-[8rem] sm:top-40 lg:top-52 2xl:top-56 left-[calc(15%)] sm:left-12 lg:left-16 w-20 h-24 xs:h-28 lg:w-28 lg:h-36 xl:h-32"
                        style="transform: rotateY(180deg)"
                        v-lazy-background :lazy-background="require('~/assets/images/illustrations/ice-cream-girl.svg')"
                    ></div>

                    {{/* Woman with basket */}}
                    <div
                        class="bg-illustration top-[5rem] sm:top-12 lg:top-[8rem] 2xl:top-[9.5rem] left-[calc(55%)] sm:left-[25rem] md:left-[30rem] lg:left-[24rem] w-20 h-32 lg:w-28 lg:h-40"
                        style="transform: rotateY(180deg)"
                        v-lazy-background :lazy-background="require('~/assets/images/illustrations/woman-with-basket.svg')"
                    ></div>
                </div>
            </div>

            <div v-else-if="theme !== 'dark'" class="z-3 absolute w-full h-full">
                {{/* People */}}

                <div class="relative container h-full">
                    {{/* Man with dog */}}
                    <div
                        class="bg-illustration top-[6.25rem] sm:top-36 lg:top-[10rem] 2xl:top-[11rem] left-[calc(15%)] md:left-[20rem] lg:left-[6.25rem] w-20 h-24 lg:w-28 lg:h-40"
                        v-lazy-background :lazy-background="require('~/assets/images/illustrations/man.svg')"
                    ></div>

                    {{/* Picnic woman */}}
                    <div
                        class="bg-illustration top-[9.4rem] sm:top-[8rem] lg:top-[15rem] 2xl:top-[16rem] left-[calc(55%)] sm:left-[25rem] md:left-[30rem] lg:left-[20rem] w-28 h-20 lg:w-36 lg:h-24"
                        v-lazy-background :lazy-background="require('~/assets/images/illustrations/picnic-woman.svg')"
                    ></div>
                </div>
            </div>

            {{/* Field */}}
            {{/* mobile */}}
            <div
                class="sm:hidden z-2 absolute bg-contain bg-top-center bg-no-repeat top-40 left-1/2 w-[110vw] h-[700px] -translate-x-1/2 opacity-30"
                v-lazy-background :lazy-background="require('~/assets/images/shapes/shape-1_gray-500.png')"
            ></div>

            {{/* desktop */}}
            <div
                class="hidden sm:block z-2 bg-illustration top-20 lg:top-18 2xl:top-4 wave-bg-responsive-parameters opacity-80"
                v-lazy-background :lazy-background="require('~/assets/images/shapes/waves/wave-2-gray-400.png')"
            ></div>

            <div v-if="theme !== 'dark'" class="z-1 absolute w-full h-full">
                {{/* Clouds & sun */}}
                <div class="relative container h-full">
                    <div
                        class="z-2 bg-illustration illustration-cloud-1 w-52 h-16 lg:w-96 lg:h-20 top-12 lg:top-32 -left-52 lg:-left-96"
                        v-lazy-background :lazy-background="require('~/assets/images/illustrations/cloud3.png')"
                    ></div>
                    <div
                        class="z-2 bg-illustration illustration-cloud-2 w-52 h-16 lg:w-96 lg:h-20 top-4 left-24 lg:left-52"
                        v-lazy-background :lazy-background="require('~/assets/images/illustrations/cloud3.png')"
                    ></div>
                    <div
                        class="z-1 bg-illustration illustration-sun w-[300px] h-[300px] lg:w-[500px] lg:h-[500px] top-52 lg:top-80 left-[calc(50%-150px)] sm:left-4"
                        v-lazy-background :lazy-background="require('~/assets/images/illustrations/sun.png')"
                    ></div>
                </div>
            </div>

            <div v-else class="z-1 absolute w-full h-full">
                {{/* Moon */}}
                <div class="relative container h-full">
                    <div
                        class="z-1 bg-illustration illustration-sun w-[200px] h-[200px] lg:w-[300px] lg:h-[300px] top-32 lg:top-40 left-4"
                        v-lazy-background :lazy-background="require('~/assets/images/illustrations/moon.png')"
                    ></div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import SocialIcon from '@/components/Generic/SocialIcon/SocialIcon.vue';
import LigetLogo from '@/components/SVGs/LigetLogo.vue';
import FooterMenuLink from '@/components/Layout/FooterMenuLink.vue';

export default {
    name: 'Footer',
    components: {
        LigetLogo,
        SocialIcon,
        FooterMenuLink,
    },
    props: {
        theme: {
            type: String,
            required: false,
            default: 'light',
            validator(value) {
                if (!['light', 'dark'].includes(value)) {
                    throw new Error("Invalid footer theme type. Valid values are 'light', 'dark'");
                }
                return true;
            },
        },
    },
    computed: {
        date() {
            const now = new Date();
            return now.getFullYear();
        },
        isVisitorInformationPage() {
            return this.$route && /^visitor-information-visitorInformation/.test(this.$route.name);
        },
        menuZones() {
            return this.$store.getters['data/getMenuZones'];
        },
        validatedCustomerSupportUrl() {
            const url = this.$t('footer.customer_support_link_url');

            if (url && /^https:\/\//.test(url)) {
                return url;
            }

            return null;
        },
    },
    mounted() {
        const scene3 = this.$scrollmagic
            .scene({ triggerElement: this.$refs.scrollBox })
            .setTween('.illustration-sun', { y: '-95%' });

        this.$scrollmagic.attachTo(this.$refs.scrollBox);

        if (this.theme !== 'dark') {
            const scene2 = this.$scrollmagic
                .scene({ triggerElement: this.$refs.scrollBox })
                .setTween('.illustration-cloud-1', { x: '90%' });
            const scene1 = this.$scrollmagic
                .scene({ triggerElement: this.$refs.scrollBox })
                .setTween('.illustration-cloud-2', { x: '50%' });
            this.$scrollmagic.addScene(scene1);
            this.$scrollmagic.addScene(scene2);
        }

        this.$scrollmagic.addScene(scene3);
    },
};
</script>

<style scoped>
.link-block {
    @apply flex flex-col items-start;
    &:not(.border-none) {
        @apply border-b border-gray-600;
    }
}
.primary-text {
    @apply font-Poppins font-semibold uppercase;
    letter-spacing: 1px;
    &:not(:first-child) {
        @apply pt-2;
    }
}

a.primary-text {
    @media (hover: hover) {
        &:hover {
            text-decoration: underline;
        }
    }
}

.secondary-text {
    @apply font-semibold;
}

a.secondary-text {
    @media (hover: hover) {
        &:hover {
            text-decoration: underline;
        }
    }
}

.vw-ref {
    opacity: 0;
}

.copyright-text:hover .vw-ref {
    opacity: 1;
}
</style>
