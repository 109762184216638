export default ({ app, $device, req, i18n, route }) => {
    //
    //
    // Add meta tags
    const meta = [];

    let host;

    if (process.server) {
        host = req.headers['x-forwarded-host'] || req.headers.host;

        meta.push({
            property: 'xs-host',
            content: host,
        });
    } else {
        host = window.location.origin;
    }

    //
    //
    // Add scripts
    const script = [];

    if (host === 'ligetbudapest.hu' && !$device.isCrawler) {
        script.push({ src: '/scripts/gtm.js' });
    }

    app.head.script.push(...script);

    // meta.push({
    //     name: 'robots',
    //     content:
    //         $config.nuxtENV === 'production' && host && /ligetbudapest\.hu/.test(host) ? 'all' : 'noindex, nofollow',
    // });

    meta.push({
        property: 'og:url',
        content: host + route.fullPath,
    });

    meta.push({
        hid: 'title',
        name: 'title',
        content: i18n.t('meta.fallback_title'),
    });

    meta.push({
        hid: 'og:title',
        name: 'og:title',
        content: i18n.t('meta.fallback_title'),
    });

    meta.push({
        hid: 'description',
        name: 'description',
        content: i18n.t('meta.fallback_description'),
    });

    meta.push({
        hid: 'og:description',
        name: 'og:description',
        content: i18n.t('meta.fallback_description'),
    });

    app.head.meta.push(...meta);
};
