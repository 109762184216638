import dayjs from 'dayjs'

import 'dayjs/locale/en'
import 'dayjs/locale/hu'
import timezone from 'dayjs/plugin/timezone'
import localeData from 'dayjs/plugin/localeData'
import updateLocale from 'dayjs/plugin/updateLocale'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(timezone)
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(isSameOrBefore)

dayjs.locale('hu')

dayjs.tz.setDefault('Europe/Budapest')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
