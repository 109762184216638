<template>
    <div>
        <span v-if="!item.url">{{ item.title }}</span>
        <a v-else-if="item.new_window" :href="item.url" target="_blank"> {{ item.title }}</a>
        <NuxtLink v-else :to="item.url">
            {{ item.title }}
        </NuxtLink>
    </div>
</template>

<script>
export default {
    name: 'FooterMenuLink',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped></style>
